<template>
  <div class="success-message">
    <h1 class="success-message-title">Premium Account coming soon!</h1>
    <div class="success-message-text">
      The expected launch date is Q2, 2024.
    </div>
    <div class="success-message-btns">
      <router-link :to="{name:'Home'}" class="btn btn-black">Back to main page</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RegistrationCompleted'
}
</script>

<style lang="less">
.success-message {
  text-align: center;
  display: table;

  max-width: 750px;
  padding: 50px;

  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  &-title {
    font-weight: bold;
    display: block;
    margin-bottom: 20px;
    font-size: 38px;
    line-height: 1.35em;
  }
  &-text {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.35em;

    a {
      color: #19191C;
      text-decoration: underline;
    }
  }
  &-btns {
    margin-top: 40px;
  }
}
</style>
